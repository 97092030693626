import { Role } from "@/models/role";

interface GtmUser {
  id: string;
  role: GtmRole;
}

export interface GtmLayerData {
  user: GtmUser;
}

export const gtmRoles = [0, 1] as const;
export type GtmRole = typeof gtmRoles[number];

export const toGtmLayerData = (role: Role, userId: string): GtmLayerData => {
  const mapper: Record<Role, GtmRole> = {
    customer: 0,
    employee: 1,
  };
  return <GtmLayerData>{
    user: {
      id: userId,
      role: mapper[role] !== undefined ? mapper[role] : -1,
    },
  };
};

export const addUserToDatalayer = (role: Role, userId: string) => {
  const data: GtmLayerData = toGtmLayerData(role, userId);
  (window as any).dataLayer?.push(data);
};

export const resetUserFromDataLayer = () => {
  (window as any).dataLayer?.push({ user: undefined });
};
