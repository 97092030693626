import layout, { Layout } from "@/store/modules/layout";
import User from "@/store/modules/user";

import documents, { Documents } from "@/store/modules/documents";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const strict = process.env.NODE_ENV !== "production";

export declare interface StoreModules {
  layout: Layout;
  user: User;
  documents: Documents;
}

export default new Vuex.Store<StoreModules>({
  modules: {
    layout,
    user: User,
    documents,
  },
  strict,
});
